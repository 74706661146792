<template>
  <div class="FacturacionListado empresas__inner container mt24 mb24">
    <ButtonBackHome />
    <header class="empresas__header">
      <h2 class="empresas_home__heading heading">Facturas solicitadas</h2>
      <div class="empresas_home__text">Aquí podrás dar seguimiento a las facturas solicitadas.</div>
    </header>
    <v-card
      flat
      :disabled="cargando"
      :loading="cargando"
    >
      <v-data-table
        class="upper"
        :headers="headers"
        :items="facturas"
        :search="search"
        :loading="cargando"
        loading-text="Cargando..."
      >
        <template v-slot:item.fecha_fso="{ item }">
          {{_doDiaMesHoras(item.fecha_fso)}}
        </template>
        <template v-slot:item.total="{ item }">
          {{$nF(item.total)}}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon v-text="'more_vert'" />
              </v-btn>
            </template>
            <v-list dark>
              <v-list-item>
                <v-list-item-title v-text="'Editar'" />
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:item.pdf_fso="{ item }">
          <v-btn
            v-if="item.pdf_fso"
            icon
            target="_blank"
            :href="$files+item.pdf_fso"
            download
          >
            <v-icon v-text="'download'" />
          </v-btn>
        </template>
        <template v-slot:item.xml_fso="{ item }">
          <v-btn
            v-if="item.xml_fso"
            icon
            target="_blank"
            :href="$files+item.xml_fso"
            download
          >
            <v-icon v-text="'download'" />
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import standard from "@/mixins/standard.js";
import dates from "@/mixins/dates.js";
export default {
  name: "FacturacionListado",
  mixins: [standard, dates],
  data() {
    return {
      idTest: null,
      headers: [
        { text: "Fecha de solicitud", value: "fecha_fso" },
        { text: "Estatus", value: "estatus_fso" },
        { text: "Uso de CFDI", value: "cfdi_fso" },
        { text: "Importe", value: "total", align: "end" },
        { text: "Metodos de pago", value: "metodos_pago" },
        { text: "PDF", value: "pdf_fso" },
        { text: "XML", value: "xml_fso" },
      ],
      search: "",
      facturas: [],
    };
  },
  created() {
    this._getAll("facturas");
  },
};
</script>