var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"FacturacionListado empresas__inner container mt24 mb24"},[_c('ButtonBackHome'),_vm._m(0),_c('v-card',{attrs:{"flat":"","disabled":_vm.cargando,"loading":_vm.cargando}},[_c('v-data-table',{staticClass:"upper",attrs:{"headers":_vm.headers,"items":_vm.facturas,"search":_vm.search,"loading":_vm.cargando,"loading-text":"Cargando..."},scopedSlots:_vm._u([{key:"item.fecha_fso",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._doDiaMesHoras(item.fecha_fso))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$nF(item.total))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('more_vert')}})],1)]}}],null,true)},[_c('v-list',{attrs:{"dark":""}},[_c('v-list-item',[_c('v-list-item-title',{domProps:{"textContent":_vm._s('Editar')}})],1)],1)],1)]}},{key:"item.pdf_fso",fn:function(ref){
var item = ref.item;
return [(item.pdf_fso)?_c('v-btn',{attrs:{"icon":"","target":"_blank","href":_vm.$files+item.pdf_fso,"download":""}},[_c('v-icon',{domProps:{"textContent":_vm._s('download')}})],1):_vm._e()]}},{key:"item.xml_fso",fn:function(ref){
var item = ref.item;
return [(item.xml_fso)?_c('v-btn',{attrs:{"icon":"","target":"_blank","href":_vm.$files+item.xml_fso,"download":""}},[_c('v-icon',{domProps:{"textContent":_vm._s('download')}})],1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"empresas__header"},[_c('h2',{staticClass:"empresas_home__heading heading"},[_vm._v("Facturas solicitadas")]),_c('div',{staticClass:"empresas_home__text"},[_vm._v("Aquí podrás dar seguimiento a las facturas solicitadas.")])])}]

export { render, staticRenderFns }